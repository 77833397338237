import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import '../AppBar/Dialog.css';
import {
    UPDATE_EXPERIMENTAL_SETTINGS
  } from '../ChatBaseContainer/ChatBaseContainerSlice'
  import {  useDispatch } from 'react-redux'

export default function AlertDialog() {
    const defaultConfig = localStorage.getItem('isFollowBackQuestionAllowed')
  const [open, setOpen] = React.useState(false);
  const [isFollowBackQuestionAllowed, setIsFollowBackQuestionAllowed] = React.useState(defaultConfig === 'true' ? true : false);

  const dispatch = useDispatch()
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    const settingConfig = localStorage.getItem('isFollowBackQuestionAllowed')
    if (settingConfig === 'true') {
        setIsFollowBackQuestionAllowed(true)
    }
    else{
        setIsFollowBackQuestionAllowed(false)
    }
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    dispatch(
        UPDATE_EXPERIMENTAL_SETTINGS({
            isFollowBackQuestionAllowed : isFollowBackQuestionAllowed
        })
      )
  };

  const handleSwitchChange = (event) => {
    setIsFollowBackQuestionAllowed(event.target.checked);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <React.Fragment>
      <SettingsIcon className='settings-icon' onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Vina's settings"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ask for follow back questions (experimental settings)
            <Switch {...label} checked={isFollowBackQuestionAllowed} onChange={handleSwitchChange} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSave} style={{
            backgroundColor: 'rgb(25,118,201)',
            color: 'white'
          }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
