import io from 'socket.io-client'
import { BASE_URL } from 'utils/Constants'
import { COMMUNICATION_MODE , COMMUNICATION_MODES } from 'utils/Constants'


const requestTimeout = 180000

export default class ChatBotSocket {
  constructor () {
    if (COMMUNICATION_MODE === COMMUNICATION_MODES.SOCKET) {
      this.socket = io(BASE_URL, { path: "/ws/socket.io/", transports: ['websocket', 'polling'] })
      this.sessionTimer = {}
    }
  }

  // called when socket is connected to the server
  onConnect (func) {
    if (!this.socket) {
      func()
      return
    }
    this.socket.on('connect', () => {
      console.log('socket connected')
      func()
    })
  }

  // called when the socket have to be disconnected from the server
  onDisconnect (func) {
    if (!this.socket) return
    this.socket.on('connect_error', (err) => func(err))
    this.socket.on('connect_failed', (err) => func(err))
    this.socket.on('disconnect', (err) => func(err))
  }

  onError (func) {
    if (!this.socket) return
    this.socket.on('chat-assistant-error', (err) => {
      console.error('Backend Error: ', err)
      func(err['sessionID'], err['message'])
    })
  }

  // called when the client have to send the Event "getPrompt" to the server with the objective "objective"
  sendObjective (requestPayload, timeoutCallback) {
    if (!this.socket) return
    // if (!this.socket) return;
    this.socket.emit('request-answer', requestPayload)
    console.log('Client sent the objective via Socket: ', requestPayload)

    this.sessionTimer[requestPayload.sessionID] = setTimeout(() => {
      this.onTimeout(timeoutCallback, requestPayload.sessionID)
    }, requestTimeout)
  }

  onTimeout (timeoutCallback, sessionID) {
    this.sessionTimer[sessionID] = null
    console.log('Timeout Occured for session: ', sessionID)
    timeoutCallback(sessionID)
  }

  onGetAnswer (func, timeoutCallback) {
    if (!this.socket) return
    this.socket.on('respond-answer', (data) => {
      
      if (this.sessionTimer[data.sessionID]) {
        clearTimeout(this.sessionTimer[data.sessionID])
        if (data.answer === '') {
          this.sessionTimer[data.sessionID] = setTimeout(() => {
            this.onTimeout(timeoutCallback, data.sessionID)
          }, requestTimeout)
        } else {
          this.sessionTimer[data.sessionID] = null
        }
        console.log('From Backend socket: ', data)
        func(data)
        
      }
    })
  }
}
