import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getKeywords } from '../services/HttpAPIRequests'

const MENTIONS_REGEXP = /@\[([^\]]+)\]\(([^)]+)\)/g
let mentionsSuggestionData = []
export function getGeographicdata () {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      // Request user's location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude

          // Use reverse geocoding service (Nominatim API) to get location information
          const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`

          fetch(nominatimUrl)
            .then((response) => response.json())
            .then((data) => {
              const locationData = {
                country: data.address.country,
                state: data.address.state,
                city: data.address.city,
                zipcode: data.address.postcode,
                Latitude: latitude,
                Longitude: longitude
              }

              // Resolve the promise with the locationData
              resolve(locationData)
            })
            .catch((error) => {
              // Reject the promise if there's an error fetching location information
              reject(new Error('Error fetching location information: ' + error))
            })
        },
        (error) => {
          // Reject the promise if there's an error getting the user's location
          reject(new Error('Error getting location: ' + error))
        }
      )
    } else {
      // Reject the promise if geolocation is not available in the browser
      reject(new Error('Geolocation not available in this browser.'))
    }
  })
}
export function updateStoreData (currentVersion) {
  // Get the stored version number from local storage
  const storedVersion = localStorage.getItem('storeDataVersion')
  if (storedVersion && storedVersion === currentVersion) {
    // No need for update
    return
  } else if (!storedVersion) {
    // set currentVersion as stored version
    localStorage.setItem('storeDataVersion', currentVersion)
    return
  }
  if (storedVersion && storedVersion === '1.1') {
    // change to specific version
    // If no version is stored or the stored version is outdated, update the data structure
    // Convert the data to the new structure

    // Example of converting data to a new structure
    const updatedData = localStorage.getItem('chatData')

    // Update the stored version number
    localStorage.setItem('storeDataVersion', currentVersion)

    // Update the local storage with the new data structure
    localStorage.setItem('chatData', JSON.stringify(updatedData))

    console.log('updated chatData to new structure')
  } else {
    console.error(
      `storeDataVersion ${storedVersion} not matching current version ${currentVersion} . contact administrator to convert your chat data`
    )
  }
}

export function getColumnOrderFromConfig (configArray) {
  for (const config of configArray) {
    if (Object.keys(config).includes('columns')) {
      return config.columns
    }
  }
  return null
}
export function downloadCSV (data, { name = 'your_data', columns = null }) {
  if (columns == null) {
    columns = Object.keys(data)
  }
  // Headers for each column
  const headers = [columns.join(',')]
  const rows = []
  for (let i = 0; i < data[columns[0]].length; i++) {
    const row = []
    for (let j = 0; j < columns.length; j++) {
      const cell = data[columns[j]][i]
      let stringCell = cell.toString()
      // Surround with double quotes to avoid splitting the contents to multiple cells
      if (stringCell.includes(',')) stringCell = `"${stringCell}"`
      row.push(stringCell)
    }
    rows.push(row)
  }
  const csvData = [headers, ...rows]
  const blob = new Blob([csvData.join('\n')], { type: 'text/csv' })

  const a = document.createElement('a')
  a.download = name + '.csv'
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

export function stepsWithCachedData (data) {
  return data.steps.map((step, stepIndex) => {
    return {
      ...step,
      visualizationCache: step.visualization?.map((plot, plotIndex) => {
        return {
          ...plot,
          id: uuidv4(),
          stepIndex,
          plotIndex,
          drawerIsOpen: true
        }
      })
    }
  })
}

export function formatMentionTextToHtml (message) {
  // Split the message into an array of parts
  const parts = message.split(MENTIONS_REGEXP)

  // Map the parts to React elements
  return parts.map((part, index) => {
    if (index % 3 === 1) {
      // Content inside @[...]()
      return (
        <span className="mention-text" key={index}>
          {part}
        </span>
      )
    } else if (index % 3 === 2) {
      // Content inside @[](...)
      return '' // Discard them
    } else {
      // Other text
      return part
    }
  })
}

export function extractMetaDataFromMentionsText (mentionsText) {
  // Split the message into an array of parts
  const parts = mentionsText.split(MENTIONS_REGEXP)
  const keyWords = []
  const TextArray = parts.map((part, index) => {
    if (index % 3 === 1) {
      const category = parts[index + 1].split('@')[0]
      // Content inside @[...]()
      keyWords.push({
        keyWord: part,
        category
      })
      return part
    } else if (index % 3 === 2) {
      // Content inside @[](...)
      return '' // Discard them
    } else {
      // Other text
      return part
    }
  })
  return {
    keyWords,
    text: TextArray.join('')
  }
}

export function extractMetaDataFromMentionsArray (mentions) {
  const allKeyWords = []
  const textArray = mentions.map((textItem) => {
    const { text, keyWords } = extractMetaDataFromMentionsText(textItem)
    allKeyWords.push(...keyWords)
    return text
  })
  return {
    textArray,
    keyWords: allKeyWords
  }
}
function convertMentionsStructure (data) {
  const result = []
  for (const KeywordMap of data) {
    for (const value of KeywordMap.values) {
      result.push({
        id: KeywordMap.category + '@' + result.length,
        category: KeywordMap.category,
        display: value,
        applicableDatasources: KeywordMap.applicable_datasources,
        searchKeywords: KeywordMap.search_keywords
      })
    }
  }
  return result
}
export function getMentionsObjectsFromArray (array, category = 'column') {
  return array.map((item, index) => {
    return {
      id: category + '@' + index,
      category,
      display: item,
      applicableDatasources: [category],
      searchKeywords: []
    }
  })
}
export function querySuggestionsData (query, ignoredDataSources, keywords) {
  const queryWords = query.toLowerCase().split(/[:,;]/)
  const _mentionsSuggestionData = keywords || mentionsSuggestionData
  return _mentionsSuggestionData.filter((item) => {
    const searchItems = [item.display, item.category, ...item.searchKeywords]
    // Check if any of applicableDatasources not present in ignoredDataSources and every query word is a substring in at least one search Item
    return (
      item.applicableDatasources.every(
        (element) => !ignoredDataSources.includes(element)
      ) &&
      queryWords.every((queryWord) =>
        searchItems.some((block) =>
          block.toLowerCase().includes(queryWord.toLowerCase())
        )
      )
    )
  })
}

export function loadKeywords () {
  // get keywords from API
  getKeywords((data) => {
    mentionsSuggestionData = convertMentionsStructure(data)
  })
}

export function generateUniqueFileName (originalFileName, listOfAllFileNames) {
  if (!listOfAllFileNames.includes(originalFileName)) return originalFileName
  // Generate new FileName in format `originalFileName (index)` until it is available
  let index = 1
  while (index <= listOfAllFileNames.length) {
    const newFileName = originalFileName + ` (${index})` // TODO : (i) keeps appending , instead of changing
    if (!listOfAllFileNames.includes(newFileName)) return newFileName
    index++
  }
  return originalFileName
}
export function downloadJSON (data, fileName) {
  const jsonString = JSON.stringify(data, null, 2)
  const blob = new Blob([jsonString], { type: 'application/json' })
  const href = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = href
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function getAttachmentsFromHistory (chatHistory, sessionAttachments) {
  const botMessagesInChatHistory = chatHistory.filter(
    (message) => message.name === 'Vina'
  )
  const attachments = {}
  for (const botmessage of botMessagesInChatHistory) {
    // add attachments from steps
    for (let i = 0; i < botmessage.value.steps.length; i++) {
      const step = botmessage.value.steps[i]
      if (step.attachment?.name) {
        if (step.attachment.data) {
          attachments[step.attachment.name] = JSON.parse(
            JSON.stringify(step.attachment)
          )
        } else if (
          Object.keys(sessionAttachments.attachments).includes(
            step.attachment.name
          )
        ) {
          attachments[step.attachment.name] = JSON.parse(
            JSON.stringify(sessionAttachments.attachments[step.attachment.name])
          )
        }
      }
    }
    // TODO: add sources when backend supports it
    // for (const step of botmessage.value.steps) {
    //   if (!step.source) continue;
    //   for (const source of step.source) {
    //     attachments[source.name] = {
    //       name: source.name,
    //       type: "link",
    //       data: source.path,
    //     };
    //   }
    // }
  }
  return attachments
}
export function addMetadataToPayload (objective, metadata) {
  const { text, keyWords } = extractMetaDataFromMentionsText(
    objective.question
  )
  const { textArray } = extractMetaDataFromMentionsArray(
    objective.chatHistory
  )

  const currentTime = new Date()
  return {
    ...objective,
    question: text,
    chatHistory: textArray,
    metaData: {
      question: { keyWords },
      locationData,
      timeStamp: currentTime.toString()
    }
  }
}
function getLocalStorageUsage () {
  const used = Math.round((JSON.stringify(localStorage).length / 1024) * 2)
  // 'used' is current usage in KB

  for (let i = 0, data = '1'.repeat(10000); ; i++) {
    try {
      // Pushes another 10000 "1" values in until localStorage maxes out
      localStorage.setItem('DATA', data)
      data = data + '1'.repeat(100000)
    } catch (e) {
      const total = Math.round((JSON.stringify(localStorage).length / 1024) * 2)
      const free = total - used
      // Removes item used to test localStorage after usage
      localStorage.removeItem('DATA')
      // 'total' is usage after it maxed out (total capacity)
      return { total, used, free, unit: 'kb' }
    }
  }
}

export function areValuesDeeplyEqual(x, y) {
  if (x === y) {
    return true;
  } else if (
    typeof x == "object" &&
    x != null &&
    typeof y == "object" &&
    y != null
  ) {
    if (Object.keys(x).length != Object.keys(y).length) return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!areValuesDeeplyEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
}

export class SessionTimer{
  static #sessionTimer = {}
  static #requestTimeout = 60000 // 60 seconds

  static startTimer(sessionID, timeoutCallback){
    this.#sessionTimer[sessionID] = setTimeout(() => {
      console.log('Timeout Occured for session: ', sessionID)
      delete this.#sessionTimer[sessionID]
      timeoutCallback(sessionID)
    }, this.#requestTimeout)
  }

  static clearTimer(sessionID){
    clearTimeout(this.#sessionTimer[sessionID])
    delete this.#sessionTimer[sessionID]
  }
  
  static isTimerRunning(sessionID){
    return this.#sessionTimer.hasOwnProperty(sessionID)
  }
}

let locationData = {}
// getGeographicdata()
//   .then((location) => {
//     locationData = location
//   })
//   .catch((error) => {
//     console.error(error)
//   })


// access from console
window.getLocalStorageUsage = getLocalStorageUsage
