// React import
import React, { useCallback, memo, useMemo } from 'react'

// External Library imports
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';

// Material-UI imports
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

// Utils import
import { areValuesDeeplyEqual } from 'utils/helpers';

// Stylesheet import
import './pdfViewer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function stringToRegex(input) {
  const alphaChars = input.match(/[a-zA-Z0-9]/g);
  if (!alphaChars) return new RegExp(input);

  const regexStr = alphaChars.map((chr) => chr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('\\s*');
  return new RegExp(regexStr);
}


function PdfViewer({ handleDatasheetPageChange, onDocumentLoadSuccess, datasheet }) {

  const totalDatasheetPages = datasheet.totalPages
  // console.log(datasheet.highlighted_text)

  // let texttohighlight = `Continuous communication  Tx/Rx FIFO size 8 bytes `
  //     const highlightrange = [[2357, 2448], [1958, 2061]]

  //     let renderindex = 0;

  // function shouldHighlight(index) {
  //   for(let i = 0; i < highlightrange.length; i++){
  //     if(index >= highlightrange[i][0] && index <= highlightrange[i][1]){
  //       return true
  //    }
  //   }
  //    return false
  // }


  // function highlightPattern(text) {
  //   console.log("Text is", text, renderindex)

  //   for(let i = 0; i < text.length; i++){
  //     // console.log("Renderindex is", renderindex, shouldHighlight(renderindex))

  //     if (shouldHighlight(renderindex)){

  //       renderindex += text.slice(i).length

  //       return `<mark>${text}</mark>`

  //     }

  //     renderindex+=1

  //   }

  //   return text

  // }


  // let hightlightwords = texttohighlight.replace(/\n/g, " ")
  let hightlightwords = ['']

  if (datasheet.highlighted_text != "") {
    const hightlightedSentence = datasheet.highlighted_text.split(/\s+/)
    hightlightwords = hightlightedSentence.map(word => word.replace(/[.,:;()]/g, '')).filter(Boolean);

    hightlightwords = hightlightedSentence.filter(word => word !== "")
    let stopwords = ['to', 'the', 'is', 'in', 'or', 'before', 'and', 'after', 'of', 'on', 'at', 'with', 'by', 'for', 'from', 'over', 'under', 'above', 'below', 'between', 'among', 'through', 'into', 'onto', 'off', 'out', 'up', 'are', 'as', 'be', 'but', 'do', 'does', 'did', 'done', 'doing', 'done', 'has', 'have', 'had', 'having', 'will', 'would', 'shall', 'should', 'may', 'might', 'must', 'can', 'could', 'am', 'is', 'are', 'was', 'were', 'been', 'being', 'a', 'an', 'the', 'this', 'that', 'these', 'those', 'here', 'there', 'where', 'when', 'why', 'how', 'which', 'what', 'who', 'whom', 'whose', 'whichever', 'whatever', 'whoever', 'whomever', 'my', 'mine', 'your', 'yours', 'his', 'her', 'hers', 'its', 'our', 'ours', 'their', 'theirs', 'was', 'were', 'am', 'is', 'are', 'be', 'been', 'being', 'have', 'has', 'had', 'do', 'does', 'did', 'doing', 'will', 'would', 'shall', 'should', 'may', 'might', 'must']
    hightlightwords = hightlightwords.filter(word => !stopwords.includes(word.toLowerCase()))
    // console.log("Hightlight words", hightlightwords)
  }


  // let hightlightwords = datasheet.highlighted_text.split(/\s+/)
  // // console.log("Hightlight words", hightlightwords)
  // hightlightwords = hightlightwords.filter(word => word !== "");
  function highlightPattern(text, highlightwords) {

    let words = text.split(" ")
    words = words.filter(word => word !== "")
    // console.log("Word is", words)
    // console.log("Words is", words)
    // console.log("Highlighted words is", highlightwords)
    // console.log ("Words is", words)
    let shouldHighLighBool = false
    for (const word of words) {
      for (let i = 0; i < highlightwords.length; i++) {
        if (highlightwords.length > 0 && word.toLowerCase() == highlightwords[i].toLowerCase()) {
          // console.log("HighLighword is", highlightwords[i])

          // console.log("Text is ", text)
          // console.log("HighLighword is", highlightwords[0], highlightwords[1])
          // console.log("Word is", word)

          // console.log(word)
          shouldHighLighBool = true
          highlightwords.splice(i, 1)
          // console.log(highlightwords)
          break
          // return `<mark>${word}</mark>`


        }
      }
      // console.log("Word is", word)
      // if (highlightwords.length > 0 && word.toLowerCase() == highlightwords[0].toLowerCase()) {
      //   // console.log("HighLighword is", highlightwords[0], highlightwords[1])
      //   // console.log("Word is", word)

      //   // console.log(word)
      //   shouldHighLighBool = true
      //   highlightwords.shift()
      //   return `<mark>${word}</mark>`


      // }

    }
    if (shouldHighLighBool) {
      return `<mark style="background-color: yellow">${text}</mark>`
    }
    // console.log(shouldHighLighBool)
    return text

  }

  const textRenderer = (textItem) => {
    // console.log(datasheet.highlighted_text);
    return highlightPattern(textItem.str, hightlightwords)
  }



  const viewerWidth = (window.innerWidth - 200) / 2
  const pdfFileName = useMemo(() => datasheet.blob_file_url, [datasheet.blob_file_url])
  // console.log(datasheet.currentPageNumber === datasheet.retreivedPageNumber)

  return (
    <div style={{ minWidth: viewerWidth + 2, borderLeft: "2px solid lightgrey", minHeight: 'calc(100vh - 160px)' }}>
      {pdfFileName !== '' && pdfFileName !== undefined ? (
        <>
          {totalDatasheetPages > 0 &&
            <Stack alignItems="center" sx={{ zIndex: 1000, background: "white", width: viewerWidth }}>
              <Pagination
                sx={{ margin: "10px auto" }}
                count={totalDatasheetPages}
                page={datasheet.currentPageNumber}
                onChange={handleDatasheetPageChange}
              />
            </Stack>
          }
          <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 212px)", marginRight: "40px" }}>
            <Document
              file={pdfFileName}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={datasheet.currentPageNumber}
                scale={viewerWidth * 0.0015}
                renderAnnotationLayer={false}
                // customTextRenderer={textRenderer}
                customTextRenderer={datasheet.currentPageNumber === datasheet.retreivedPageNumber ? textRenderer : ''}
              />
            </Document>
          </div>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <span>No reference document to display related to this question</span>

        </div>
      )}
    </div>
  )
}

const memoizedPdfViewer = memo(PdfViewer, arePropsEqual)

function arePropsEqual(prevProps, nextProps) {
  return areValuesDeeplyEqual(prevProps.datasheet, nextProps.datasheet)
}

export default memoizedPdfViewer