// React imports
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// Material-UI imports
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/Info'
import Avatar from '@mui/material/Avatar'

import { getTodaysEvent, getUserImage } from 'services/HttpAPIRequests'
import SettingsIcon from '@mui/icons-material/Settings'
import AlertDialog from '../AppBar/Dialog'

// Image import
import SolitonLogo from 'assets/images/logo.png'
import VinaBotIcon from 'assets/images/Vina_bot_icon.svg'
// Constants
import { IS_MOBILE, APPBAR_VINA_DESCRIPTION } from 'utils/Constants'

// Stylesheet import
import './AppBar.css'

let navItems = ['Vina']
const navItemsmap = {
  Vina: 'Ask',
  Analyzer: 'Analyze',
  About: <InfoIcon sx={{ pointerEvents: 'none' }} />
}
if (IS_MOBILE) {
  navItems = ['Vina'] // Don't include Analyzer in mobile
}
export default function SimpleAppBar (props) {
  const onClick = props.onClick
  const mode = props.mode
  const [todaysEvent, setTodaysEvent] = useState({ name: null, image: null })
  const [userImageUrl, setUserImageUrl] = useState('userImage')
  const userName = useSelector(
    (state) => state.ChatBaseContainer.userName
  )
  useEffect(() => {
    // TODO : display page only when today's event response received to avoid glitch
    // getTodaysEvent((event) => {
    //   setTodaysEvent(event)
    // })
  }, [])


  useEffect(() => {
    if (userName) {
      getUserImage((data) => {
        const urlCreator = window.URL || window.webkitURL
        const url = urlCreator.createObjectURL(data)
        setUserImageUrl(url)
      })
    }
  }, [userName])

  const appBarStyle = {

  }
  if (todaysEvent.image) {
    appBarStyle.backgroundImage = `url('${todaysEvent.image}')`
  }

  function displayClick(event) {
    console.log(event.target.value)
  
  }

  return (
    <div className="app-bar-base">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar component="nav" position="static" sx={appBarStyle} >
          <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'stretch' }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '@media (min-width: 300px)': { display: 'block' }
              }}
              className="element App-bar-title"
            >
              <img className='soliton-logo' src={SolitonLogo} alt="Soliton Logo" />
            </Typography>
            {!IS_MOBILE &&
            <div className='element vina-logo-container'>
              <img className="vina-logo" src={VinaBotIcon}/>
              <div>
              <h2 style={{ marginBottom: 0 }}>Vina </h2>
              <p
                style={{
                  bottom: '-0.10em',
                  fontSize: '0.8em',
                  marginBottom: 0
                }}
              >
                {APPBAR_VINA_DESCRIPTION}
              </p>

              </div>
            </div>
            }
            <AlertDialog/>
            {/* <SettingsIcon className='settings-icon' onClick = {displayClick}/> */}
            <Box
            className='element'
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {/* {navItems.map((item) => (
                <Button
                  key={item}
                  id={mode === item ? 'selected' : 'not-selected'}
                  sx={{ color: '#fff' }}
                  onClick={onClick}
                  title={item}
                >
                  {navItemsmap[item]}
                </Button>
              ))} */}
              {userName &&
              <Box>
                <Tooltip title={userName} arrow>
                <Avatar alt={userName} src={userImageUrl}/>
                </Tooltip>
                </Box>
                }
            </Box>
          </Toolbar>
          
          
        </AppBar>
      </Box>

    </div>
  )
}
