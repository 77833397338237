// React imports
import React, { useEffect, useRef, memo } from 'react'
import { useSelector,useDispatch } from 'react-redux'

import { UPDATE_DATASHEET } from 'container/ChatBaseContainer/ChatBaseContainerSlice'

import { areValuesDeeplyEqual } from 'utils/helpers';

// Component imports
import Tviz from '../Message/t-viz/t-viz'
import Viz from '../Message/viz/viz'
import RenderMarkdown from '../Message/RenderMarkdown/RenderMarkdown'
import BotProgress from '../bot_progress/BotProgress'
import LikeDislikeCheckboxes from '../like_dislike/like_dislike'
import SelectAttachments from '../SelectAttachments/SelectAttachments'
// Material-UI imports
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import ReplayIcon from '@mui/icons-material/Replay'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DownloadIcon from '@mui/icons-material/Download'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AiGreyIcon from 'assets/images/aiIcon-grey.svg'

// Constants and utility function imports
import { BOT_NAME, IS_MOBILE } from 'utils/Constants'
import {
  getColumnOrderFromConfig,
  downloadCSV,
  getAttachmentsFromHistory
} from 'utils/helpers'

// Stylesheet import
import './ChatViz.css'

import OfflineBoltIcon from '@mui/icons-material/OfflineBolt'
import Tooltip from '@mui/material/Tooltip'
const accordianStyle = {
  boxShadow: 'none',
  '& .MuiAccordionSummary-root': {
    flexDirection: 'row-reverse',
    paddingLeft: '0px'
  },
  '& .MuiAccordionSummary-content': {
    gap: '1em',
    alignItems: 'center'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)'
  }
}

const ChatViz = ({
  history,
  vizSize,
  botProgress,
  onLikeClick,
  onDisLikeClick,
  responseStream,
  updatePlot,
  editMessage,
  activeSession,
  sendDataToAnalyzer,
  attachments,
  navigateDatasheetPage
}) => {
  // Initialize the state
  const [, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  // Call when the props is changed
  useEffect(() => {
    setTimeout(
      () => lastMessage.current?.scrollIntoView({ behavior: 'smooth' }),
      0
    )
  }, [history.length])

  const lastMessage = useRef('')
  // TODO : Remove onHighlightClick , setOpen , handleOpen , ScrollDialog
  const onHighlightClick = React.useCallback(
    (step, stepIndex, messageId) => {
      handleOpen(true)
    },
    [history]
  )
  const onRegenerateResponse = () => {
    for (let i = history.length - 1; i >= 0; i--) {
      if (history[i].name === 'user') {
        editMessage({
          id: history[i].id,
          type: history[i].name,
          message: history[i].value,
          action: 'submit',
          selectedAttachments: history[i].selectedAttachments
        })
        break
      }
    }
  }
  const allowUserInteraction = !botProgress && !responseStream 
  return (
    <div className="Chat-Viz">
      {showChats(
        history,
        onHighlightClick,
        vizSize,
        onLikeClick,
        onDisLikeClick,
        updatePlot,
        editMessage,
        allowUserInteraction,
        sendDataToAnalyzer,
        attachments,
        navigateDatasheetPage
      )}
      {botProgress && !responseStream && <BotProgress />}

      <div ref={lastMessage}></div>
      {history.length>0 && allowUserInteraction && (
        <Button
          className="RegenerateButton"
          variant="outlined"
          onClick={onRegenerateResponse}
          sx={{
            width: 'fit-Content',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '50px',
            scale: '0.9'
          }}
        >
          <ReplayIcon sx={{ marginRight: '0.3em' }} />
          Regenerate
        </Button>
      )}
      {/* <ScrollDialog
        open={open}
        handleClose={handleClose}
        stepDetails={stepDetails}
      /> */}
    </div>
  )
}
// export default ChatViz

const memoizedChatVizualizer = memo(ChatViz,arePropsEqual)

function arePropsEqual(prevProps, nextProps) {
  return areValuesDeeplyEqual(prevProps.history, nextProps.history) && areValuesDeeplyEqual(prevProps.navigateDatasheetPage, nextProps.navigateDatasheetPage) && areValuesDeeplyEqual(prevProps.responseStream, nextProps.responseStream) && areValuesDeeplyEqual(prevProps.editMessage, nextProps.editMessage)
}

export default memoizedChatVizualizer

function showChats (
  history,
  onHighlightClick,
  vizSize,
  onLikeClick,
  onDisLikeClick,
  updatePlot,
  editMessage,
  allowUserInteraction,
  sendDataToAnalyzer,
  attachments,
  navigateDatasheetPage
) {
  const _selectedAttachments = []
  if (history.length === 0) {
    return (
      <div className='no-history'>
        <div>
          <div className='full-width'>
            <img className='ai-icon' src={AiGreyIcon}/>
          </div>
          I am here to answer your questions related to STM32U5 series.
        </div>
      </div>
    )
  }
    return history.map((chat, index) => {
    _selectedAttachments.push(chat.selectedAttachments)
    const attachmentsUptoMessage = getAttachmentsFromHistory(
      history.slice(0, index),
      attachments
    )
    if (chat.name === 'user') {
      return (
        <div key={index} className="Chat-User">
          <div className="bot-title">
            <div id="bot-name">You</div>
            {
              <SelectAttachments
                attachments={{
                  attachments: attachmentsUptoMessage,
                  selected: chat.selectedAttachments
                }}
                onAttachmentUpdate={(_attachments) => {
                  _selectedAttachments[index] = _attachments.selected
                }}
                scope={chat.isEditing ? 'messageEdit' : 'message'}
              />
            }
            {/* {chat.selectedAttachments.map((attachmentName, index) => {
              const attachment = attachments.attachments[attachmentName];
              const attachmentTooltip = attachmentToolTipMap[attachment.type];
              return (
                <Tooltip key={index} title={attachmentTooltip} arrow>
                  {attachment.type === "DataFrame" ? (
                    <span
                      className="attachmentName"
                      onClick={() =>
                        downloadCSV(attachment.data, {
                          name: attachment.name,
                        })
                      }
                    >
                      {attachmentName}
                    </span>
                  ) : (
                    <a
                      className="attachmentName"
                      target="_blank"
                      rel="noreferrer"
                      href={attachment.data}
                    >
                      {attachmentName}
                    </a>
                  )}
                </Tooltip>
              );
            })} */}
          </div>
          <Tviz
            text={chat.value}
            id={chat.id}
            type={'user'}
            editMessage={(props) => {
              editMessage({
                ...props,
                selectedAttachments: _selectedAttachments[index]
              })
            }}
            allowUserInteraction={allowUserInteraction}
            isEditing={chat.isEditing}
          />
        </div>
      )
    } else if (chat.name === 'Vina') {
      const answer = chat.value.answer
      const steps = chat.value.steps
      return (
        <div key={index} className="Chat-Vina text-response">
          <div className="bot-title">
            <div id="bot-name">{BOT_NAME}</div>
            {chat.value.edited
              ? (
                  '(edited)'
                )
              : (
              <LikeDislikeCheckboxes
                checkState={chat.feedbackState}
                onLikeChangeEvent={() => onLikeClick(index)}
                onDislikeChangeEvent={() => onDisLikeClick(index)}
              />
                )}
          </div>
          <Tviz
            text={answer}
            id={chat.id}
            editMessage={editMessage}
            type={'vina'}
            allowUserInteraction={allowUserInteraction}
            isEditing={chat.isEditing}
          />
          
          {!IS_MOBILE && chat.value.source_nodes?.length > 0 && 
          <div style={{display:"flex",gap:"0.5em", flexWrap: "wrap"}}>
            <b>Reference nodes:</b>
          {chat.value.source_nodes.map((node, index) => {
            return (<a href='#' key={index} onClick={()=>navigateDatasheetPage(node)} title={node.text}><span>{index + 1}.</span> {node.file_name} - (Pg no.{node.page})</a>)
          })}
          </div>} 

          <div >   
          {steps.map((step, i) => {
            return (

              <div className="text-response-display" key={i} >
                {displayAttachment(
                  step,
                  i,
                  {
                    messageId: chat.id,
                    vizSize,
                    updatePlot
                  },
                  onHighlightClick,
                  sendDataToAnalyzer,
                  attachments,
                  chat.value.reference_nodes,
                  navigateDatasheetPage

                )}
              </div>
            )
          })}
          </div>
          {/* <div className="thought-process">
            <strong>My reasoning:</strong>
            <div
              className="thought-process-button"
              onClick={() => onHighlightClick(index)}
              title={index}
            >
              <img
                className="thought-process-button"
                src={HighlightIcon}
                title={index}
                alt="Thought-Process"
              />
            </div>
          </div> */}
        </div>
      )
    } else {
      return <></>
    }
  })
}

function isDataFrameValid (dataFrame) {
  // check undefined or null type
  if (!dataFrame) return false
  const dataFrameKeys = Object.keys(dataFrame)
  // check for empty dataFrame
  if (dataFrameKeys.length === 0) return false
  return true
}
function displayAttachment (
  step,
  stepIndex,
  { messageId, vizSize, updatePlot },
  onHighlightClick,
  sendDataToAnalyzer,
  attachments,
  referenceNodes,
  navigateDatasheetPage
) {
  if (step.datasource_name == "Synthesized response and other relevant pages extracted") {
    return (
      <div className="step-description">
        
        <Accordion sx={accordianStyle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CheckCircleIcon sx={{ color: '#37b637', fontSize: '2em' }} />
            <div className="dataframe-datasource">
              <span className="dataSource_name">
                {step.datasource_name}
              </span>
            </div>
            {renderSourceLinks(step)}
          </AccordionSummary>
          <AccordionDetails>
            <RenderMarkdown markdown={compileStepDetails(step)} />
            <div style={{display:"flex",gap:"0.5em", flexWrap: "wrap"}}>
            <b>Reference nodes:</b>
          {referenceNodes.map((node, index) => {
            return (<a href='#' key={index} onClick={()=> navigateDatasheetPage(node)} title={node.text}><span>{index + 1}.</span> {node.file_name} - (Pg no.{node.page})</a>)
          })}
          </div>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  } else if (step.attachment && step.attachment.type === 'DataFrame') {
    const attachment = attachments.attachments[step.attachment.name]
    const isAttachmentDataValid = isDataFrameValid(attachment.data)
    return (
      <div>
        <div className="step-description">
          <Accordion sx={accordianStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CheckCircleIcon sx={{ color: '#37b637', fontSize: '2em' }} />
              <div className="dataframe-datasource">
                <span className="dataSource_name">
                  {step.datasource_name}
                </span>
                {renderSourceLinks(step)}
                {isAttachmentDataValid && (
                  <span
                    className="dataFrame_name"
                    onClick={(e) => {
                      e.stopPropagation()
                      downloadCSV(attachment.data, {
                        name: attachment.name,
                        columns: getColumnOrderFromConfig(
                          step.visualizationCache
                        )
                      })
                    }}
                  >
                    Data : {attachment.name}{' '}
                    <DownloadIcon sx={{ position: 'relative', top: '5px' }} />{' '}
                  </span>
                )}
              </div>
              {isAttachmentDataValid && (
                <AddCircleRoundedIcon
                  sx={{ color: '#1976d2', cursor: 'pointer', fontSize: '2em' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    updatePlot(
                      {
                        type: 'table',
                        xColumn: '',
                        yColumn: '',
                        groupBy: '',
                        drawerIsOpen: true,
                        stepIndex,
                        plotIndex: 0,
                        plotAction: 'Create'
                      },
                      messageId
                    )
                  }}
                />
              )}
              {isAttachmentDataValid && !IS_MOBILE && (
                <Tooltip title="Send to analyzer">
                  <OfflineBoltIcon
                    sx={{
                      color: '#1976d2',
                      cursor: 'pointer',
                      fontSize: '2em'
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      sendDataToAnalyzer({
                        fileName: attachment.name + '.csv',
                        data: attachment.data
                      })
                    }}
                  />
                </Tooltip>
              )}
            </AccordionSummary>

            <AccordionDetails>
              <div className="stepDetails">
                {!isAttachmentDataValid && (
                  <div>The Attachment Data is Invalid </div>
                )}
                <RenderMarkdown
                  markdown={compileStepDetails(step, attachment)}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {isAttachmentDataValid &&
          step.visualizationCache.map((plot, index) => {
            const chatClass = 'chat-plot-' + plot.id
            let _vizSize = vizSize

            // get dataFrame length
            const df = attachment.data
            const dfKeys = Object.keys(df)
            const dfLength = df[dfKeys[0]].length
            // adjust plot height based on dataFrame length
            if (dfLength < 3) {
              _vizSize = vizSize + ' height-reduced'
            }

            return (
              <div className={'Chat-Viz-Boundry ' + chatClass} key={chatClass}>
                <CloseIcon
                  className="close-icon"
                  sx={{
                    backgroundColor: 'white',
                    cursor: 'pointer',
                    fontSize: '1.4em',
                    position: 'absolute',
                    right: '0.5em',
                    zIndex: '1',
                    transform: 'translateY(3px)'
                  }}
                  onClick={() => {
                    updatePlot(
                      {
                        stepIndex: plot.stepIndex,
                        plotIndex: plot.plotIndex,
                        plotAction: 'Delete'
                      },
                      messageId
                    )
                  }}
                />
                <Viz
                  chatClass={chatClass}
                  dataframe={attachment.data}
                  plotType={plot.type}
                  plotConfig={plot}
                  vizSize={_vizSize}
                  index={index}
                  messageId={messageId}
                  updatePlot={updatePlot}
                  dataframeName={attachment.name}
                />
              </div>
            )
          })}
      </div>
    )
  } else if (step.datasource_name) {
    return (
      <div className="step-description">
        <Accordion sx={accordianStyle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CheckCircleIcon sx={{ color: '#37b637', fontSize: '2em' }} />
            <div className="dataframe-datasource">
              <span className="dataSource_name">
                {step.datasource_name}
              </span>
            </div>
            {renderSourceLinks(step)}
          </AccordionSummary>
          <AccordionDetails>
            <RenderMarkdown markdown={compileStepDetails(step)} />
          </AccordionDetails>
        </Accordion>
      </div>
    )
  } else if (step.stream_message) {
    return (
      <div className="step-description">
        <Accordion sx={accordianStyle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CircularProgress size="1.8em" />
            <div className="dataframe-datasource">
              <span className="dataSource_name">{step.stream_message}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="stepDetails"><RenderMarkdown
                  markdown={step.stream_operation}
                />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }
}

// eslint-disable-next-line no-unused-vars
const formatChatResponseWithLinks = (chat) => {
  const response = chat.data
  const links = chat.supportData.source

  const usedLinks = []
  const usedLinkName = []

  const messageArray = response.split(/\[(.*?)\]/)

  const displayableText = messageArray.map((word, index) => {
    const link = links.find(
      (item) => item.name.toLowerCase() === word.toLowerCase()
    )
    const linkIdx = links.findIndex(
      (item) => item.name.toLowerCase() === word.toLowerCase()
    )

    if (link) {
      if (!usedLinkName.includes(link.name)) {
        usedLinks.push(
          <a
            key={index}
            href={link.path}
            target="_blank"
            rel="noreferrer"
            className="bot-related-links-element"
            title={link.name}
          >
            {'#' + (linkIdx + 1) + ' ' + link.name}
          </a>
        )
        usedLinkName.push(link.name)
      }
      return (
        <a
          key={index}
          href={link.path}
          target="_blank"
          rel="noreferrer"
          className="bot-related-links-element"
          title={link.name}
        >
          {'#' + (linkIdx + 1) + ' '}
        </a>
      )
    }
    return word
  })

  return { response: displayableText, links: usedLinks }
}

// eslint-disable-next-line no-unused-vars
const linksToUI = (links) => {
  return links.map((link, index) => (
    <a
      key={index}
      href={link.path}
      target="_blank"
      rel="noreferrer"
      className="bot-related-links-element"
      title={link.path}
    >
      {'#' + (index + 1) + ' ' + link.name}
    </a>
  ))
}

const compileStepDetails = (step, attachment) => {

  let stepDetails = `
  <strong>Operation</strong><br />
${step.operation}<br />
<strong>Response</strong><br />
${step.response}<br />
  `
  if (step.search_technique) {
    stepDetails += `<strong>Search Technique</strong><br />
${step.search_technique}<br/>`
  }

  if (attachment && attachment.type === 'links') {
    const links = attachment.data.map((link) => `[${link.name}](${link.path})`)
    stepDetails += `<strong>Attachment links</strong><br />
    ${links.join('<br />')}`
  }

  return stepDetails
}
function renderSourceLinks (step) {
  if (step.source && step.source.length > 0) {
    return (
      <div
        style={{
          display: 'flex',
          gap: '1em',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <strong>Source links : </strong>

        {step.source.map((link, index) => (
          <a href={link.path} rel="noreferrer" target="_blank" key={index}>
            #{link.name}
          </a>
        ))}
      </div>
    )
  }
  return <></>
}
